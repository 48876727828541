

.iconSpace  {
    color: #001fe6;
    margin-left: 20px;
    transition: transform .5s;
}

.iconSpace:hover {
    transform: scale(1.2);
}

.profileShadow {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    margin-left: 15px;
    margin-bottom: 5px;
    margin-top: 20px;
}

.titleH1 {
    --s: 0.1em;   /* the thickness of the line */
    --c: #2c4bff; /* the color */
    
    color: #0000;
    padding-bottom: var(--s);
    background: 
      linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
      linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
    -webkit-background-clip: text,padding-box;
            background-clip: text,padding-box;
    transition: 0.5s;
  }
  .titleH1:hover {--_p: 100%}
  
  

  .titleH1 {
    font-style: bold;
    cursor: pointer;
  }